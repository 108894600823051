<template>
  <div class="mains" v-loading="loading" element-loading-spinner="el-icon-loading">
    <div class="breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/iotclassifitwins' }">孪生体分类</el-breadcrumb-item>
        <el-breadcrumb-item>新增分类</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form-box">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px">
        <!-- 基本信息 -->
        <div class="form-title">基本信息</div>
        <el-form-item label="项目名称" prop="">
          <el-input v-model="form.projectName" disabled></el-input>
        </el-form-item>
        <el-form-item label="分类名称" prop="classificationName">
          <el-input v-model="form.classificationName" placeholder="请输入分类名称" show-word-limit maxlength="20"></el-input>
        </el-form-item>
        <div class="bottom-btn">
          <el-button type="primary" size="small" @click="$router.push('/iotclassifitwins')">取消</el-button>
          <el-button type="primary" @click="onSubmit" size="small">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
export default {
  data() {
    return {
      loading: false,
      twinsDrawer: false,
      treeLoading: false,
      tableTwinsLoading: false,
      staffId: null,
      current: null,
      form: {},
      rules: {
        classificationName:[{ required: true, message: '必填项', trigger: 'blur' }],
      },
      parkProps: {
        children: 'childTree',
        label: 'name'
      },
      parkCode: '',
      parkData: [],

      tableData: [],
      tableDataCp: [],
      tableSelectList: []
    }
  },
  methods: {
    dayjs,
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let form = {
            projectId: this.projectId,
            type: this.form.classificationName,
          }
          this.loading = true
          this.$ajax.post('uinoDigitalTwinTypeCreate', form).then(res => {
            this.loading = false
            this.$message.success('成功')
            this.$router.push('/iotclassifitwins')
          }).catch(err=>{
            this.loading = false
          })
        }
      })
    },
    // 切换园区
    parkClick(data) {
      this.current = data.name
      this.queryTwinsUnderThepark()
    },
    // 清空所选孪生体
    delTwins() {
      this.tableData = []
      this.tableSelectList = []
    },
    // 打开选择孪生体侧滑面板
    selectTwins() {
      setTimeout(()=>{ //高亮选中
        this.$refs.parkTree.setCurrentKey(this.parkData[0].id)
      },200)
      this.queryTwinsUnderThepark()
      this.twinsDrawer = true
    },
    // 勾选孪生体数据
    checkeChange(row) {
      if (row.checked) {
        // 勾选
        this.tableSelectList.push(row)
      } else {
        this.tableSelectList.forEach((item, index) => {
          if (item.id == row.id) {
            this.tableSelectList.splice(index, 1)
          }
        })
      }
    },
    // 确认选择孪生体数据
    twinsDrawerOK() {
      this.tableData = []
      if(this.tableSelectList.length==0) return this.$message.error('请选择孪生体')
      this.tableSelectList.forEach(item=>{
        this.tableData.push(item)
        this.twinsDrawer = false
      })
    },
    // 删除已选择孪生体信息
    dltTwins(row){
      this.tableData.forEach((item, index) => {
        if (item.id == row.id) {
          this.tableData.splice(index, 1)
        }
      })
      this.tableSelectList.forEach((v,k) => {
        if (v.id == row.id) {
          this.tableSelectList.splice(k, 1)
        }
      })
    },
    // 获取孪生体分类
    loadClassifyList() {
      this.treeLoading = true
      this.$ajax.post('iotUinoClassifQuery', {
        companyId: this.companyId,
        staffId: this.staffId,
        projectId: this.projectId
      }).then(res => {
        this.current = res.data[0]
        res.data.forEach(v=>{
          this.parkData.push({id: v, name: v})
        })
        this.treeLoading = false
      }).catch(err=>{
        this.treeLoading = false
      })
    },
    // 获取园区下对应的孪生体数据
    queryTwinsUnderThepark() {
      this.tableTwinsLoading = true
      this.$ajax.post('iotTwinsQuery', {
        companyId: this.companyId,
        staffId: this.staffId,
        projectId: this.projectId,
        park: this.current,
      }).then(res => {
        res.data.forEach(v=>{
          v.checked = false
        })
        this.tableDataCp = res.data
        this.tableTwinsLoading = false

        this.tableDataCp.forEach((v)=>{ // 已选择的设备项高亮选中
          if (this.tableSelectList.find(item => item.id == v.id)) {
            v.checked = true
          }else {
            v.checked = false
          }
        })
      }).catch(err => {
        this.tableTwinsLoading = false
      })
    }
  },
  mounted() {
    if (this.$route.params.row == null) {
			this.$router.push('/iotclassifitwins')
		} else {
      this.form = this.$route.params.row
      this.companyId = this.form.companyId
      this.staffId = sessionStorage.getItem('id')
      this.projectId = this.form.projectId
    }
  }
}
</script>

<style lang="less" scoped>
.mains {
  height: 100%;
}
/deep/.el-loading-spinner .el-icon-loading{
  font-size: 50px;
}
.breadcrumb {
  padding: 15px 10px;
  .el-breadcrumb {
    font-size: 18px;
  }
}
.table-tree {
  display: flex;
  height: ~'calc(100% - 56px)';
  margin-top: 20px;
  .tree {
    width: 300px;
    border-right: 4px solid #f6f7fb;
    margin-right: 20px;
  }
  .table {
    flex: 1;
  }
  .operate {
    &:hover {
      color: #409EFF;
    }
  }
  .custom-tree-node {
    font-size: 14px;
  }
}
/deep/.el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #409eff;
  color: white;
}
</style>